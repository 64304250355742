@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import "Component/ImageOver/ImageOver.css";
$theme-colors: (
        "ec1": #2f6184,
        "ec2": #eeac0e,
        "ec3": rgba(255, 255, 255, 0)
);

$list-group-action-active-bg: #fff;
$list-group-action-active-color: #fff;

@import "~bootstrap/scss/bootstrap";

.card-columns {
    @include media-breakpoint-down(lg) {
        column-count: 1;
    }
    @include media-breakpoint-up(lg) {
        column-count: 2;
    }
}

.text-limit {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.image-card {
    object-fit: cover;
    filter: brightness(30%);
}

.back-to-top {
    bottom: 50px;
    right: 25px;
}

.top-to-back {
    top: 8em;
    left: 2.5em;
    width: 12.5em;
    height: 5em;
}

body {
    background: #fff;
    font-family: "Roboto", sans-serif;
}

.hr {
    height: .25rem;
    width: 25%;
    margin: 0;
    background: #eeac0e;
    border: none;
    transition: .3s ease-in-out;
}

.nav-border {
    border: 1px solid color("ec2") !important;
    height: 100%;
    width: 0;
}

.loading-pos{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0turn);
}
.App-logo {
    height: 9em;
    object-fit: contain;
}

.border2 {
    border-width: 3px !important;
}

.grecaptcha-badge { visibility: hidden; }
