.Navbar {
    background: linear-gradient(deepskyblue,dodgerblue);
}

.Navlink {
    color: black;
    align-items: center;
    alignment-baseline: center;
    text-align: left;
    align-content: flex-start;
}

.Logo{
    height: 40px;
    width: 75px;
    margin-top: 0px;
    float: left;
}

.flag{
    margin: 5px;
    height: 20px;
    width: 30px;
}

.menu {
    margin-top: 8px;
    margin-left: 20px;
    font-size: 14px;
    color: black;
}
