
.image_size {
    position: relative;
    padding-bottom: 100%;
    height: 100%;
    width: 100%;
}

.imageStyle {
    object-fit: contain;
    filter: brightness(30%);
    transform: scale(1.2);
}

.test2 {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    object-fit: contain;
}

